import React from 'react';
import { DateTime } from 'luxon';

export class WrappedBirthdayTimer extends React.Component {
  // Hacky shortcut...
  getFutureDate () {
    let baseYear = 2023;
    let nextDate = null;

    const nowTime = (new Date()).getTime();

    while (!nextDate) {
      const date = new Date(`${baseYear}-05-04T00:00:00.000Z`);

      console.log('DATE!', {
        date,
        nowTime,
        dateTime: date.getTime(),
        comp: nowTime < date.getTime()
      });

      if (nowTime < date.getTime()) {
        nextDate = date;
        console.log('DATE!', date);
      } else {
        baseYear = baseYear + 1;
      }
    }
    
    return nextDate;
  }

  render () {
    const nextDate = this.getFutureDate();

    console.log('nextDate!', nextDate);

    return (<BirthdayTimer fixedDate={nextDate} direction='up' />)
  }

}

export class BirthdayTimer extends React.Component {
  tick() {
    this.forceUpdate();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render () {
    const birthDate = DateTime.fromJSDate(this.props.fixedDate).minus({ hour: 1 }).toUTC()
    const now = DateTime.now().toUTC();
    const diff = now.diff(birthDate, ["months", "days", "hours", "minutes", "seconds"]).toObject();
    
    const timeUntilString = Object.entries(diff).map(([key, value]) => {
      const v = Math.round(Math.abs(value));

      if (v === 0) {
        return undefined;
      }

      return `${v} ${key}`
    })
    .filter(Boolean)
    .reduce((output, segment, index, allSegments) => {
      const isSecondLast = index + 1 === allSegments.length - 1;

      if (isSecondLast) {
        return `${output} and ${segment}`
      }

      return !output ? segment : `${output}, ${segment}`;
    }, '');

    return (
      <span>{timeUntilString}</span>
    )
  }
}

// direction: 'up' | 'down'
// unit: 'days' | undefined

export default WrappedBirthdayTimer;