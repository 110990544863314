import React from 'react';
import styled from 'styled-components';
import './App.css';
import BirthdayTimer from './components/timer';

const MainImage = styled.img`
  margin: 0 auto;
  width: 80%;
  max-width: 32rem;
`;

const Image = styled.img`
  margin: 0 auto;
  width: 80%;
  max-width: 32rem;
`;

const Para = styled.p`
  max-width: 36rem;
  margin: 1rem auto;
  display: inline-block;
`;


function App() {
  return (
    <main>
      <h1>April Robinson</h1>
      <MainImage src={process.env.PUBLIC_URL + '/images/main.png'} />
      <h2>Birthday: 4th May, 1991</h2>
      <Para><em>Next birthday in <BirthdayTimer /></em></Para>
      <hr color='#FFF' style={{ width: '100%', maxWidth: '36rem' }} />
      <Para>
        Happy Birthday, April! I can't really match your skills at cake making and baking, so I thought why not show my love in a way
        that I actually <em>can</em> do?
      </Para>
      <Para>
        <b>I'm squatting on this web domain for you in case for any reason you ever want it!</b> When you become an internationally acclaimed superstar (as Peagreen's pupeteer, of course),
        then this domain would be really hard to buy!
      </Para>
      <Para>
        But while I've got your attention, let's take a look at some of these photos I've got here for a trip down memory lane, after all - it's your birthday!
      </Para>
      <hr color='#FFF' style={{ width: '100%', maxWidth: '36rem' }} />
      <h2>April Takes a Break with Teddies</h2>
      <Image src={process.env.PUBLIC_URL + '/images/teddies.JPG'} />
      <Para>
        This must be part of your descent into madness - at least you look happy!
      </Para>
      <h2>Tinfoil with Tim (Tim-foil?)</h2>
      <Image src={process.env.PUBLIC_URL + '/images/tinfoil.JPG'} />
      <Para>
        I'm in this one! This doesn't feel that long ago and yet I'm pretty sure it's from 2017... blimey. Anyway, at least the government didn't get to our brains.
      </Para>
      <h2>Sad in the Rain</h2>
      <Image src={process.env.PUBLIC_URL + '/images/rain.JPG'} />
      <Para>
        I <em>think</em> this one is older than the last one. I don't remember at all why someone was taking a picture of us standing sadly in the rain... but here we are.
      </Para>
      <h2>Elfing Around</h2>
      <Image src={process.env.PUBLIC_URL + '/images/elf.JPG'} />
      <Para>
        Okay, yes, we're definitely getting younger here. Look at that plastic water bottle I'm holding, and -- look at your phone! It's blue with polkadots? That doesn't seem very you, but then again here we are dressed as elves, so anything goes. Still, definitely getting younger.
      </Para>
      <h2>Oh man, Oman</h2>
      <Image src={process.env.PUBLIC_URL + '/images/young.JPG'} />
      <Para>
        You know what? I bet we thought we were really cool and grown-up here, even though I'm all slouchy and weird and you're <em>definitely</em> posing ;)
      </Para>
      <h2>School Days</h2>
      <Image src={process.env.PUBLIC_URL + '/images/school.JPG'} />
      <Para>
        This seems like an absolute lifetime ago. You look perfectly normal, and then there's toothy boss-eyed Tim! I'm also clearly stating my dominance with my hands firmly on the table.
      </Para>
      <h2>Old, but Gold</h2>
      <Image src={process.env.PUBLIC_URL + '/images/old.JPG'} />
      <Para>
        We've grown up a lot together. There's no part of me that remembers being there for this photo, but I will always cherish it.
      </Para>
      <Para>
        Sure, we've had times where you wouldn't stop calling me a loser (which was clearly never true),
        and we've had fallings out from time to time (whatever the fight was about, I was definitely in the right),
        but you've never stopped being my cool big sister.
      </Para>
      <Para>
        I'm very proud to be your little brother. I love you very much.
      </Para>
      <Para>
        Happy Birthday.  Tim xxx
      </Para>
    </main>
  );
}

export default App;
